import React from "react";

import styles from "./Footer.module.css";

import products from "../products.json";

import { MainContext, useContext } from "../../context/context";

const Footer = () => {
  const { clearBasket, basket } = useContext(MainContext);

  let total = 0;

  return (
    <footer className={styles.footer}>
      <div className={styles.top}>
        {basket.length > 0 && (
          <button onClick={clearBasket} className={styles.clear}>
            Clear Basket{" "}
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M19 24h-14c-1.104 0-2-.896-2-2v-16h18v16c0 1.104-.896 2-2 2zm-7-10.414l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm10-8.586h-20v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2zm-8-3h-4v1h4v-1z" />
            </svg>
          </button>
        )}

        {basket.length > 0 && (
          <div className={styles.bill}>
            <ul>
              <li>
                <span>Name</span>
                <span>Amount</span>
                <span>Price</span>
              </li>
              {basket.map((item) => {
                const product = products.find((i) => i.id === item.id);
                total += item.amount * product.price;
                return (
                  <li key={item.id}>
                    <span>{product.name}</span>
                    <span>x{item.amount}</span>
                    <span>{item.amount * product.price}$</span>
                  </li>
                );
              })}
              <li>
                <span></span>
                <span></span>
                <span>Total: {total}$</span>
              </li>
            </ul>
          </div>
        )}


      </div>

      <div className={styles.bottom}>
        
<p>
Welcome to the exciting world of Spend Elon Musks Money! In this game, you get to be in charge of Elon Musks huge fortune of $225.4 billion. Imagine stepping into the shoes of this amazing entrepreneur and making important decisions in a virtual marketplace full of possibilities. Your choices will shape the fate of billions of dollars!
<br/><br/>
As you spend the money, a virtual version of Elon Musk might appear, playfully questioning your decisions. This adds a fun and challenging aspect to the game, making you think about the impact of your choices.
<br/><br/>
What's new in the Spend Elon Musk Money Game? The timer and receipt system work together to make the game even more interesting. Managing your time becomes important, and the receipt feature encourages you to plan and think carefully. Now, you not only have to make big decisions, but you also need to do it quickly, adding an extra layer of challenge and excitement to managing Elon Musk's fortune.
<br/><br/>
Spend Elon Musk Money is more than just a game; it's like a rollercoaster of emotions involving financial power and responsibility. Congratulations on getting to experience the thrill of handling one of the biggest fortunes in the world. Who knows, maybe Elon Musk could learn something from your choices in real life? We'll have to wait and see!
  </p>


      </div>
    </footer>
  );
};

export default Footer;
